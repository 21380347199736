<template>
  <div v-show="$store.state.common.sIsLoading">
    Loading...
  </div>

  <div v-show="!$store.state.common.sIsLoading">
    <div style="width:100%; padding:10px 100px; box-sizing:border-box;">
      <div style="width:100%; padding:10px; box-sizing:border-box; border:solid 1px #dcdcdc; border-radius:10px; background-color:#fffff0; margin-bottom:10px;">
        <div style="display:inline-block; width:100%; text-align:center; padding: 0px 10px; box-sizing:border-box; color:#696969;">
          <div style="display:inline-block; width:50%; text-align:left; font-size:1.5em;">
            <b>{{ vDataSc.com_name }}</b>
          </div>
          <div style="display:inline-block; width:50%; text-align:right;">
            <small><span style="background-color:#696969; color:#ffffff; border-radius:20px; padding:5px; box-sizing:border-box;"><b>{{ vDataSc.sc_status_name }}</b></span></small>
          </div>
        </div>

        <div style="display:inline-block; width:100%; padding:10px 5% 0; box-sizing:border-box; text-align:left; color:#696969;">
          <b>実施：</b>{{ vDataSc.sc_yearmonth }}
        </div>
        <div style="display:inline-block; width:100%; padding:10px 5% 0; box-sizing:border-box; text-align:left; color:#696969;">
          <b>アンケート期間：</b>{{ vDataSc.sc_answer_start_date }}～{{ vDataSc.sc_answer_end_date }}
        </div>
        <div style="display:inline-block; width:100%; padding:10px 5% 0; box-sizing:border-box; text-align:left; color:#696969;">
          <b>集団分析の実施：</b>{{ vDataSc.sc_group_analysis_name }}
        </div>
        <div style="display:inline-block; width:100%; padding:10px 5% 0; box-sizing:border-box; text-align:left; color:#696969;">
          <b>実施対象者数：</b>{{ vScTargetCount.toLocaleString() }}人
        </div>
        <div style="background-color:#dcdcdc; border-radius:10px; display:inline-block; width:80%; padding:10px 0; box-sizing:border-box; color:#696969;">
          <small>
            <div style="display:inline-block; width:100%; padding:0 10%; box-sizing:border-box; text-align:left;">
              <div style="display:inline-block; width:15%; box-sizing:border-box; text-align:left;">
                <b>回答：</b>
              </div>
              <div style="display:inline-block; width:40%; box-sizing:border-box; text-align:left;">
                <b>{{ vNameDetail5ex.a }}／</b>{{ vScConfAnswerPaperCount.toLocaleString() }}人
              </div>
              <div style="display:inline-block; width:40%; box-sizing:border-box; text-align:left;">
                <b>{{ vNameDetail5ex.b }}／</b>{{ vScConfAnswerWebCount.toLocaleString() }}人
              </div>
            </div>
            <div style="display:inline-block; width:100%; padding:10px 10% 0; box-sizing:border-box; text-align:left;">
              <div style="display:inline-block; width:15%; box-sizing:border-box; text-align:left;">
                <b>結果通知：</b>
              </div>
              <div style="display:inline-block; width:40%; box-sizing:border-box; text-align:left;">
                <b>{{ vNameDetail6ex.a }}／</b>{{ vScConfNoticePaperCount.toLocaleString() }}人
              </div>
              <div style="display:inline-block; width:40%; box-sizing:border-box; text-align:left;">
                <b>{{ vNameDetail6ex.b }}／</b>{{ vScConfNoticeWebCount.toLocaleString() }}人
              </div>
            </div>
          </small>
        </div>
        <!-- <div style="display:inline-block; width:100%; text-align:left; padding:10px; box-sizing:border-box; color:#696969;">
          実施期間終了後に、アンケートの回答結果を分析し、インテリジェントヘルスケア株式会社が作成するストレスチェック分析結果用紙をお送りします。
        </div> -->
      </div>
    </div>

    <div style="padding:10px; padding-left:10%;">
      <h4 style="text-align:left; color:#696969;">厚労省ツールの自動分析を実行してください。</h4>
    </div>

    <div style="padding:10px; padding-left:10%;">
      <div style="width:100%; text-align:left; margin-bottom:10px;">
        <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" style="width:20%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doConfirmUpdate(vModalIdAnalysisConfirm)">
          自動分析実行
        </button>
      </div>
    </div>

    <!-- 取込実行確認モーダル -->
    <div :id="vModalIdAnalysisConfirm" uk-modal>
      <div class="uk-modal-dialog" uk-margin-auto-vertical style="padding:20px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <p style="color:#696969;"><b>自動分析を実行しますか？</b></p>
        <div style="width:100%; text-align:right; margin-bottom:10px; display:flex; justify-content:space-between;">
          <button type="button" class="operate-button" style="width:20%; line-height:3; background-color:#ffffff; border-radius:10px; border:solid 1px #696969; color:#696969;" @click="doHideModal(vModalIdAnalysisConfirm)">
            キャンセル
          </button>
          <button :disabled="$store.state.common.sServerProcessing" type="button" class="operate-button" style="width:20%; line-height:3; background-color:#696969; border-radius:10px; border:solid 1px #696969; color:#ffffff;" @click="doExecuteAutoAnalysis">
            OK
          </button>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
  import constant from '@/constant.js'
  import UIkit from 'uikit'

  export default {
    data() {
      return {
        vScId: this.$store.state.sc_matter.sScId,
        vDataSc: [],
        vDataAnswer: [],
        vNameDetail5ex: {
          'a': '',
          'b': ''
        },
        vNameDetail6ex: {
          'a': '',
          'b': ''
        },
        vScTargetCount: 0,
        vScConfAnswerPaperCount: 0,
        vScConfAnswerWebCount: 0,
        vScConfNoticePaperCount: 0,
        vScConfNoticeWebCount: 0,

        vModalIdAnalysisConfirm: 'modal-intelligent-analysis',
      }
    },
    created() {
      // 画面読み込み中・・・
      this.$store.state.common.sIsLoading = true;
      // ログイン状態判定
      this.doAuthLoginInfo()
      if (!this.$store.state.common.sIsLogined) {
        this.$router.push('/login')
      }
      // 画面タイトル
      this.$store.commit('common/doSetScreenTitle', {
        vScreenTitle: 'インテリジェント/厚労省ツール自動分析実行'
      });
      // DB参照処理
      if (this.vScId) {
        this.vMessage = '';
        this.$axios.get(constant.cServerRoute.intelligent.analysis, {
          params: {
            pScId: this.vScId
          }
        })
          .then(function(response){
            console.log(response);
            // 実施管理データ参照処理判定
            if (response.data.return_result == constant.cServerReturnResult.success) {
              // 実施管理データを保持する
              if (response.data.return_data_sc) {
                this.vDataSc = response.data.return_data_sc;
              }
              if (response.data.return_data_answer) {
                this.vDataAnswer = response.data.return_data_answer;
                this.vScTargetCount = this.vDataAnswer.length;
              }
              // 表示名情報を保持する（回答形式/抽出）
              if (response.data.return_data_name5ex) {
                this.vNameDetail5ex.a = response.data.return_data_name5ex.a;
                this.vNameDetail5ex.b = response.data.return_data_name5ex.b;
                this.doGetScTargetDetailCount(constant.cScConfForCalcText.answer);
              }
              // 表示名情報を保持する（結果通知形式/抽出）
              if (response.data.return_data_name6ex) {
                this.vNameDetail6ex.a = response.data.return_data_name6ex.a;
                this.vNameDetail6ex.b = response.data.return_data_name6ex.b;
                this.doGetScTargetDetailCount(constant.cScConfForCalcText.notice);
              }
            } else {
              this.vMessage = response.data.return_message;
            }
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.vMessage = error;
          })
      }
    },
    // 画面読み込み終わった？
    mounted() {
      this.$store.state.common.sIsLoading = false;
    },
    methods: {
      // 実施対象人数内訳取得
      doGetScTargetDetailCount(situation) {
        var tmpArray = [];
        switch (situation) {
          // 回答
          case constant.cScConfForCalcText.answer:
            // 紙
            tmpArray = this.vDataAnswer.filter(item => item.sc_conf_answer == constant.cConfAnswer.paper);
            this.vScConfAnswerPaperCount = tmpArray.length;
            // Web
            tmpArray = this.vDataAnswer.filter(item => item.sc_conf_answer == constant.cConfAnswer.web);
            this.vScConfAnswerWebCount = tmpArray.length;
            break;
          // 結果通知
          case constant.cScConfForCalcText.notice:
            // 紙
            tmpArray = this.vDataAnswer.filter(item => item.sc_conf_notice == constant.cConfNotice.paper);
            this.vScConfNoticePaperCount = tmpArray.length;
            // Web
            tmpArray = this.vDataAnswer.filter(item => item.sc_conf_notice == constant.cConfNotice.web);
            this.vScConfNoticeWebCount = tmpArray.length;
            break;
        }
      },
      // 自動分析実行
      doExecuteAutoAnalysis() {
        // ボタン制御
        this.$store.commit('common/doStartServerProcess');
        this.$axios.post(constant.cServerRoute.intelligent.analysis, {
          pScId: this.vScId,
        })
          .then(function(response){
            console.log(response);
            // 自動分析実行処理判定
            if (response.data.return_result == constant.cServerReturnResult.success) {
              // 更新メッセージ表示
              this.$store.commit('common/doNotificationUpdate', {
                'vMode': constant.cNotificationUpdateMode.success
              });
              // モーダル閉じる
              this.doHideModal(this.vModalIdAnalysisConfirm);
              // // 値クリアしておく
              // this.doClear();
              // インテリジェントホームへ
              this.$router.push('/intelligent-home');
            } else {
              this.vMessage = response.data.return_message;
            }
            // ボタン制御
            this.$store.commit('common/doEndServerProcess');
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.vMessage = error;
          })
          // ボタン制御
          this.$store.commit('common/doEndServerProcess');
      },
      // DB更新前確認処理
      doConfirmUpdate(modalId) {
        UIkit.modal('#' + modalId).toggle();
      },
      // モーダル閉じる
      doHideModal(modalId) {
        UIkit.modal('#' + modalId).hide();
      },
      // ID,パスワード照合
      doAuthLoginInfo() {
        if (sessionStorage.getItem('storageId')) {
          let createHash = require("sha256-uint8array").createHash;
          let tmp = createHash().update(sessionStorage.getItem('storagePw')).digest("hex");
          this.$axios.post(constant.cServerRoute.common.login, {
            pLoginId: sessionStorage.getItem('storageId'),
            pLoginPw: tmp
          })
          .then(function(response){
            console.log(response);
            if (response.data.return_result != constant.cServerReturnResult.success) {
              this.$store.state.common.sIsLogined = false;
            }
          }.bind(this))
          .catch(function(error){
            console.log(error);
            this.$store.state.common.sIsLogined = false;
          })
        }
      }
    },
    // ページを離れる
    beforeRouteLeave() {
      UIkit.modal('#' + this.vModalIdAnalysisConfirm).$destroy(true);
    }
  }

</script>
